import { Turbo } from "@hotwired/turbo-rails"

import { isCalendlyEvent } from '../utils'

import ahoy from 'ahoy.js'
ahoy.configure({
  visitsUrl: '/t/visits',
  eventsUrl: '/t/events',
})

import 'swiper/css/bundle'

import { Application } from 'stimulus'
const application = Application.start()

import controllers from "./controllers/*_controller.js"
controllers.forEach((controller) => {
  var controllerName = controller.name.replace("controllers--", "")
  var controllerImpl = controller.module.default
  application.register(controllerName, controllerImpl)
})

import AriaControls from 'common-frontend/controllers/aria_controls_controller'
application.register('aria-controls', AriaControls)

// address autocomplete
import GooglePlacesController from "common-frontend/controllers/google_places_controller"
application.register("google-places", GooglePlacesController)

/*
 * Google Maps callback - this will be called when <script> for maps.googleapis.com finishes loading
 */
window.initMap = function (...args) {
  const event = document.createEvent('Events')
  event.initEvent('google-maps-callback', true, true)
  event.args = args
  window.dispatchEvent(event)
}

/*
 * include a few forms that are in sales-bot
 */
import GroupRequired from '../sales_bot/controllers/group_required_controller'
application.register('group-required', GroupRequired)
import ValidatedForm from '../sales_bot/controllers/validated_form_controller'
application.register('validated-form', ValidatedForm)
import Autocomplete from '../sales_bot/controllers/autocomplete_controller'
application.register('autocomplete', Autocomplete)

/*
 * Disclosures
 */

document.addEventListener('turbo:load', function (event) {
  const disclosures = document.querySelectorAll('[data-disclosure]')
  for (const disclosure of disclosures) {
    disclosure.addEventListener('click', function (event) {
      if (disclosure.classList.contains('open')) {
        disclosure.classList.remove('open')
      } else {
        disclosure.classList.add('open')
      }
    })

    if(`#${disclosure.id}` === document.location.hash) {
      disclosure.classList.add('open')
    }
  }
})

/*
 * Sticky Scroll Sections
 */

import stickybits from 'stickybits'

let observer

document.addEventListener('turbo:load', function (event) {
  if (observer) {
    observer.disconnect()
  }

  const root = document.querySelector('.sticky-scroll-section')
  if (!root) return
  const nav = root.querySelector('.sticky-nav')
  const sections = root.querySelector('.sections')
  const offset = 75

  stickybits(nav, {
    stickyBitStickyOffset: offset,
  })

  const sectionLinks = nav.querySelectorAll('[data-section]')
  for (const link of sectionLinks) {
    link.addEventListener('click', function (event) {
      const el = sections.querySelector(
        `[data-section="${link.dataset.section}"]`
      )
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop
      const rect = el.getBoundingClientRect()
      window.scrollTo({
        top: rect.top + scrollTop - offset,
        left: 0,
        behavior: 'smooth',
      })
    })
  }

  observer = new IntersectionObserver(
    (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          nav
            .querySelectorAll('[data-section]')
            .forEach((link) => link.classList.remove('selected'))
          const link = nav.querySelector(
            `[data-section="${entry.target.dataset.section}"]`
          )
          if (link) link.classList.add('selected')
        }
      })
    },
    {
      rootMargin: '0px 0px -90%',
      threshold: 0,
    }
  )

  sections
    .querySelectorAll(`[data-section]`)
    .forEach((section) => observer.observe(section))
})

/*
 * Hamburger nav
 */

document.addEventListener('turbo:load', function (event) {
  const nav = document.querySelector('.mobile-main-nav')
  if (!nav) {
    return
  }
  const hamburger = nav.querySelector('.hamburger')
  if (!hamburger) {
    return
  }
  hamburger.addEventListener('click', function (event) {
    if (nav.classList.contains('open')) {
      nav.classList.remove('open')
    } else {
      nav.classList.add('open')
    }
  })
})

/* Testimonials See More */

document.addEventListener('turbo:load', function (event) {
  const trigger = document.querySelector('[data-testimonials-trigger]')
  if (!trigger) return
  const container = document.querySelector('[data-testimonials-container]')
  trigger.addEventListener('click', function (event) {
    event.preventDefault()
    if (container.classList.contains('open')) {
      container.classList.remove('open')
    } else {
      container.classList.add('open')
    }
  })
})

/* Custom Pattern Mismatch Validation Messages */

document.addEventListener('turbo:load', function () {
  document
    .querySelectorAll('[data-pattern-mismatch-message]')
    .forEach((element) => {
      element.addEventListener('invalid', function (event) {
        if (event.target.validity.patternMismatch) {
          event.target.setCustomValidity(element.dataset.patternMismatchMessage)
        }
      })
      element.addEventListener('input', function (event) {
        event.target.setCustomValidity('')
      })
    })
})

document.addEventListener('turbo:load', function () {
  document
    .querySelectorAll('[data-type-mismatch-message]')
    .forEach((element) => {
      element.addEventListener('invalid', function (event) {
        if (event.target.validity.typeMismatch) {
          event.target.setCustomValidity(element.dataset.typeMismatchMessage)
        }
      })
      element.addEventListener('input', function (event) {
        event.target.setCustomValidity('')
      })
    })
})
/* Calendly Events */

window.addEventListener('message', function (e) {
  if (isCalendlyEvent(e) && e.data.event === 'calendly.event_scheduled') {
    fbq('trackSingle', 'Schedule')
    gtag('event', 'conversion', {
      send_to: 'AW-867535085/KKnvCMKZ6doBEO2R1p0D',
    })
    window.uetq = window.uetq || []
    window.uetq.push('event', 'Schedule')

    // Simulate page view in Mouseflow for conversion/funnel analysis
    window._mfq = window._mfq || []
    window._mfq.push(['newPageView', '/schedule-call-complete'])
  }
})
